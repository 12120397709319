<template>
  <div class="overflow-x-auto">
    <t-table :headers="headers" :data="users" class="text-sm">
      <template slot="row" slot-scope="props">
        <tr>
          <td :class="props.tdClass">{{ props.row.name }}</td>
          <td
            v-for="(date, index) in dates"
            :key="index"
            :class="props.tdClass"
          >
            <template v-if="props.row[date]">
              <template v-for="(value, name, index) in props.row[date].time">
                <span
                  v-if="props.row[date]"
                  :class="isAvailable(props.row, date)"
                  :key="index"
                >
                  {{ index > 0 ? " &" : "" }}
                  <span :class="isUnplanned(props.row, date, value)">{{
                    value
                  }}</span>
                </span>
              </template>
            </template>
          </td>
          <td :class="props.tdClass">
            <span :class="{ 'text-red-500': props.row.total < 1 }">
              {{ props.row.total }}
            </span>
          </td>
          <td :class="props.tdClass">
            <div class="flex gap-x-12">
              <div>{{ props.row.total_per_week }}</div>
              <div v-if="props.row.job_filter" class="text-gray-400">
                <ul class="list-square">
                  <li>
                    {{ $t("works_in") }}
                    {{ getJobFilter(props.row.job_filter) }}
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </t-table>
  </div>
</template>

<script>
import { find, get, includes } from "lodash";

export default {
  name: "CheckRoosterTable",
  props: {
    headers: {
      type: Array,
      default() {
        return { headers: [] };
      },
    },
    users: {
      type: Array,
      default() {
        return { users: [] };
      },
    },
    dates: {
      type: Array,
      default() {
        return { dates: [] };
      },
    },
  },
  computed: {
    jobFilters() {
      return [
        {
          type: 1,
          text: this.$i18n.t("kitchen"),
        },
        {
          type: 2,
          text: this.$i18n.t("non_kitchen"),
        },
        {
          type: 3,
          text: this.$i18n.t("dishes"),
        },
      ];
    },
  },
  methods: {
    isAvailable(data, date) {
      return get(data, `${date}.times.available`, false) || "text-red-500";
    },
    isUnplanned(data, date, time) {
      return {
        "text-gray-400": includes(get(data, `${date}.unplanned_times`), time),
      };
    },
    getJobFilter(data) {
      return get(find(this.jobFilters, { type: data }), "text");
    },
  },
};
</script>

<style lang="scss" scoped></style>
